
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },

    isDetailpage: {
      type: Boolean,
      default: false,
    },

    hasSideMenu: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    containerStyle() {
      if (this.hasSideMenu) {
        return 'pb-8 sm:container-pb container-px inner-px lg:sidebar:container-sidebar-pr'
      }

      if (this.isDetailpage) {
        return 'container-pb-image'
      }

      return 'pb-8 sm:container-pb container-x-narrow lg:sidebar:container-sidebar-pr'
    },
  },
}
